<template>
  <RealisasiVariableKpiKorporatForm mode="Tambah" module="Realisasi Variable Indikator Kinerja">
  </RealisasiVariableKpiKorporatForm>
</template>

<script>
import RealisasiVariableKpiKorporatForm from './form';

const RealisasiVariableKpiKorporatAdd = {
  name: 'RealisasiVariableKpiKorporatAdd',
  components: { RealisasiVariableKpiKorporatForm },
};

export default RealisasiVariableKpiKorporatAdd;
</script>
